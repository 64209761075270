<template>
  <div class="user-profile-container">
    <div class="user-profile-card">
      <div class="user-header">
        <p>User Information</p>
        <div class="submit-button">
        <div class="save">
          <b-button @click="handleSubmit">Save</b-button>
        </div>
      </div>
      </div>
      <form @submit.prevent="handleSubmit">
          <div class="columns">
            <div class="column is-10-mobile is-6-tablet is-4-desktop padding">
              <input-vue
                :required="true"
                :value="user.firstName"
                :min="1"
                type="text"
                :max="150"
                :submitted="submitted"
                @onChangeName="onChangeFirstName"
                label="First Name" />
              <div v-if="submitted && isFirstNamePatternInvalid" class="required-error">{{namePatternErrorMessage}}</div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-10-mobile is-6-tablet is-4-desktop padding">
              <input-vue
                :required="true"
                :value="user.lastName"
                :min="1"
                type="text"
                :max="150"
                :submitted="submitted"
                @onChangeName="onChangeLastName"
                label="Last Name" />
              <div v-if="submitted && isLastNamePatternInvalid" class="required-error">{{namePatternErrorMessage}}</div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-10-mobile is-6-tablet is-4-desktop padding">
              <input-vue
                :required="false"
                :value="user.email"
                :min="1"
                :disabled="true"
                type="email"
                :max="150"
                :readonly="true"
                :submitted="submitted"
                @onChangeName="onChangeEmail"
                label="Email" />
            </div>
          </div>
          <div class="password-header ">
            <p>Change Password</p>
          </div>
          <div class="columns">
            <div class="column is-10-mobile is-6-tablet is-4-desktop padding">
              <input-vue
                :required="false"
                :value="user.currentPassword"
                :min="1"
                type="password"
                :max="150"
                :passwordReveal="true"
                :submitted="submitted"
                @onChangeName="onChangeCurrentPassword"
                :readonly="true"
                @focus="handleFocus"

                label="Current Password" />
                <div
                  v-if="submitted && isCurrentPasswordInvalid==0"
                  class="required-error">
                  Current Password is required.
                </div>
                <!-- <div
                  v-else-if="submitted && isCurrentPasswordInvalid==1"
                  class="required-error">
                  Current Password must be at least 8 characters.
                </div> -->
            </div>
          </div>
          <div class="columns">
            <div class="column is-10-mobile is-6-tablet is-4-desktop padding">
              <input-vue
                :required="false"
                :value="user.newPassword"
                :min="1"
                type="password"
                :max="150"
                :passwordReveal="true"
                :submitted="submitted"
                @onChangeName="onChangeNewPassword"
                @focus="handleFocus"
                :readonly="true"

                label="New Password" />
                <div
                  v-if="submitted && isNewPasswordInvalid==0"
                  class="required-error">
                  New Password is required.
                </div>
                <div
                  v-else-if="submitted && isNewPasswordInvalid==1"
                  class="required-error">
                  Length must be between 8 and 100 characters. Must contain one uppercase letter, one lowercase letter, and one digit.
                </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-10-mobile is-6-tablet is-4-desktop padding">
              <input-vue
                :required="false"
                :value="user.confirmPassword"
                :min="1"
                type="password"
                :max="150"
                :passwordReveal="true"
                :submitted="submitted"
                @onChangeName="onChangeConfirmPassword"
                @focus="handleFocus"
                :readonly="true"
                label="Confirm New Password" />
                <div
                  v-if="submitted && isConfirmPasswordInvalid==0"
                  class="required-error">
                  Confirm New Password is required.
                </div>
                <div
                  v-else-if="submitted && confirmError==0"
                  class="required-error">
                  New Password and Confirm Password are not matching.
                </div>
            </div>
          </div>
          <div class="password-header" v-if="userDetails.modules.map(d => d.module_name).includes('voc')">
            <p>Preferences</p>
            <div class="columns">
            <div class="column is-10-mobile is-6-tablet is-4-desktop padding">
            <div class="each-dropdown">
          <SingleSelectorVue
            label="Category"
            placeholder="Select category"
            className="category-width"
            :showNested="false"
            :showRadio="true"
            :list="categoryList"
            :selected="selected"
            @onChange="onChangeCategory" />
        </div>
        </div>
          </div>
          </div>
        </form>
  </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { metaTitle } from '../../../../constant/data'
import InputVue from '../../../Admin/components/Input.vue'
import SingleSelectorVue from '../../../../components/Dropdowns/SingleSelector.vue'
import { re, allowedNamePattern } from '@/util/util'

export default {
  name: 'user-profile',
  metaInfo () {
    return {
      title: metaTitle.profile
    }
  },
  components: {
    InputVue,
    SingleSelectorVue
  },
  data () {
    return {
      user: {
        firstName: null,
        lastName: null,
        email: null,
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
      },
      isFirstNameInvalid: false,
      isLastNameInvalid: false,
      isFirstNamePatternInvalid: false,
      isLastNamePatternInvalid: false,
      isCurrentPasswordInvalid: null,
      isNewPasswordInvalid: null,
      isConfirmPasswordInvalid: null,
      namePatternErrorMessage: 'Please provide a valid input. Only letters, numbers, spaces, - and _ are allowed.',
      confirmError: null,
      submitted: false,
      newDefault: null
    }
  },
  computed: {
    ...mapState('user', ['userDetails']),
    ...mapState('common', ['defaultCategory', 'selectedCategories', 'categoryList', 'subCategories']),
    defaultSubCategories () {
      if (this.userDetails.preferences.defaultCategory) {
        return this.userDetails.preferences.defaultCategory.subCategories
      }
      return []
    },
    selected () {
      const exists = () => {
        return this.categoryList.some(cat => JSON.stringify(cat) === JSON.stringify(this.defaultCategory))
      }
      if (this.defaultCategory === null || !exists()) {
        return this.selectedCategories
      }
      return this.defaultCategory
    }
  },
  mounted () {
    this.getUser()
    this.getCategoryData()
  },
  methods: {
    ...mapActions('user', [
      'getUserDetails',
      'updateUserDetails',
      'setUserDetails'
    ]),
    ...mapActions('filters', ['getDataForGlobalFilters']),
    ...mapActions('common', ['updateDefaultCategory', 'getCategoryData', 'updateSelectedNestedCategories']),
    onChangeNestedData (data) {
      this.updateSelectedNestedCategories(data)
    },
    onChangeCategory (data) {
      this.newDefault = data
    },
    onChangeFirstName (name, isInvalid) {
      this.user.firstName = name
      this.isFirstNameInvalid = isInvalid
      if (!isInvalid && !allowedNamePattern.test(name)) {
        this.isFirstNamePatternInvalid = true
      } else {
        this.isFirstNamePatternInvalid = false
      }
    },
    onChangeLastName (name, isInvalid) {
      this.user.lastName = name
      this.isLastNameInvalid = isInvalid
      if (!isInvalid && !allowedNamePattern.test(name)) {
        this.isLastNamePatternInvalid = true
      } else {
        this.isLastNamePatternInvalid = false
      }
    },
    onChangeEmail (name, isValid) {
      this.user.email = name
    },
    handleFocus (e) {
      e.removeAttribute('readonly')
    },
    onChangeCurrentPassword (name, isValid) {
      if (name == null) {
        name = ''
      }
      this.user.currentPassword = name
      this.checkPasswordValidation()
    },
    onChangeNewPassword (name, isValid) {
      if (name == null) {
        name = ''
      }
      this.user.newPassword = name
      this.checkPasswordValidation()
    },
    onChangeConfirmPassword (name, isValid) {
      if (name == null) {
        name = ''
      }
      this.user.confirmPassword = name
      this.checkPasswordValidation()
    },
    async getUser () {
      const { firstName, lastName, email } = this.userDetails
      // const user = JSON.parse(localStorage.getItem('user'))
      // if (user != null) {
      //   const data = await this.getUserDetails(user.userId)
      this.user.firstName = firstName
      this.user.lastName = lastName
      this.user.email = email
      // }
    },
    checkPasswordValidation () {
      if ((this.user.newPassword !== '') ||
        (this.user.newPassword !== '') ||
        (this.user.currentPassword !== '')) {
        if (!this.user.currentPassword.length) {
          this.isCurrentPasswordInvalid = 0
        } else {
          this.isCurrentPasswordInvalid = null
        }
        //  else if (this.user.currentPassword.length < 8) {
        //   this.isCurrentPasswordInvalid = 1
        // }

        if (!this.user.newPassword.length) {
          this.isNewPasswordInvalid = 0
        } else if (!re.test(this.user.newPassword)) {
          this.isNewPasswordInvalid = 1
        } else {
          this.isNewPasswordInvalid = null
        }

        if (!this.user.confirmPassword.length) {
          this.isConfirmPasswordInvalid = 0
        } else {
          this.isConfirmPasswordInvalid = null
        }

        if (this.user.newPassword.length && this.user.confirmPassword.length) {
          if (this.user.newPassword.length !== this.user.confirmPassword.length) {
            this.confirmError = 0
          } else {
            this.confirmError = null
          }
        }
      } else {
        this.isCurrentPasswordInvalid = null
        this.isNewPasswordInvalid = null
        this.isConfirmPasswordInvalid = null
      }
    },
    async handleSubmit (e) {
      this.submitted = true
      this.checkPasswordValidation()
      this.updateDefaultCategory(this.newDefault)
      if (!(
        this.isFirstNameInvalid ||
        this.isLastNameInvalid ||
        this.isFirstNamePatternInvalid || 
        this.isLastNamePatternInvalid || (!(this.isCurrentPasswordInvalid === null &&
          this.isNewPasswordInvalid === null &&
          this.isConfirmPasswordInvalid === null && this.confirmError === null)))
      ) {
        const res = await this.updateUserDetails(this.user)
        if (res.status === 200) {
          this.user.currentPassword = ''
          this.user.newPassword = ''
          this.user.confirmPassword = ''
        }
      }
    }
  }
}
</script>

<style lang="scss" src="./UserProfile.scss" scoped/>
